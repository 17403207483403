$homepage-signup-form-margin: 50px 0 0 !important;
$homepage-signup-form-padding: 46px 0 !important;


$homepage-signup-form-left-content-max-width:400px;
$homepage-signup-form-left-content-margin: 0 100px 0 auto;
$homepage-signup-form-left-content-text-align: center;

$homepage-signup-form-title-font-family: $font-family-secondary;
$homepage-signup-form-title-font-size: 40px;
$homepage-signup-form-title-font-color: $gray-dark2;
$homepage-signup-form-title-font-weight: 600;
$homepage-signup-form-title-line-height: 48px;

$homepage-signup-form-title-after-width: 40px;
$homepage-signup-form-title-after-height: 8px;
$homepage-signup-form-title-after-margin: 15px auto;
$homepage-signup-form-title-after-background-image: url('../images/home/icons/swirl.svg');

$homepage-signup-form-paragraph-font-family: $font-family-base;
$homepage-signup-form-paragraph-font-size: 20px;
$homepage-signup-form-paragraph-font-color: $gray-light2;
$homepage-signup-form-paragraph-font-weight: $font-weight-normal;
$homepage-signup-form-paragraph-line-height: 22px;
$homepage-signup-form-paragraph-margin: 0;


//tablet

$homepage-signup-form-margin\tablet: 35px 0 0 !important;
$homepage-signup-form-padding\tablet: 20px 0 !important;

$homepage-signup-form-left-content-max-width\tablet: inherit;
$homepage-signup-form-left-content-margin\tablet:  0 auto;

$homepage-signup-form-title-font-size\tablet: 25px;
$homepage-signup-form-title-line-height\tablet: 31px;

$homepage-signup-form-paragraph-font-size\tablet: 18px;
$homepage-signup-form-paragraph-line-height\tablet: 20px;

$homepage-signup-form-button-font-size\tablet: 16px;


//Mobile
$homepage-signup-form-margin\mobile: 30px 0 0 !important;