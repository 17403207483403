.page-wrapper{
    overflow-x: hidden;
}
#maincontent { // sass-lint:disable-line no-ids
    margin-top: 0;
}
html {
    scroll-padding-top: $anchor-sticky-desktop;

    @include mq($max-screen: $screen-l - 1) {
        scroll-padding-top: $anchor-sticky-tablet;
    }

    @include mq($max-screen: $screen-m - 1) {
        scroll-padding-top: $anchor-sticky-mobile;
    }
}

.tablet-hidden {
    @include mq($max-screen: $screen-l - 1) {
        display: none !important;
    }
}

.tablet-only {
    @include mq($screen-l - 1) {
        display: none !important;
    }
}

/* Upsell Popup Cart Style */

body {
    .dummy-checkout-upsell {
        display: none;
    }

    .modal-upsell.modal-popup {
        .modal__close-button {
            top: 4px;
            right: 4px;
            background: none;
            z-index: 20;
            &:before{
                content: "";
                display: block;
                width: 34px;
                height: 34px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: url("../images/icons/close.svg") no-repeat;
                background-size: contain;

            }
        }

        &:not(.confirm){
            .modal-header {
                padding: 0;
            }
        }


        .modal-content {
            padding-bottom: 0;
        }

        &.modal-slide .modal-footer {
            text-align: center;
            border: none;
        }

        .modal-inner-wrap {
            max-width: 1028px;

            .modal__close-button-icon {
                transform: scale(1.5);
                fill: #292F38;
                display: block;
            }
        }

        &.modal-slide {
            &._inner-scroll {
                .modal-header {
                    padding: 0;
                }
            }
        }

        #popup-upsell-cart {
            .slider__nav-button {
                border-radius: 50%;
            }

            .slider__navigation {
                top: 40%;

                .slick-disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }

                .slider__nav-button {
                    .slider__nav-icon {
                        display: none;
                    }
                }
                .slider__next,
                .slider__prev{
                    border-width: 2px;
                    border-color: #878A8E;

                    &:hover{
                        background: $orange;
                        border-color: $orange;

                        &:before{
                            background: $orange;
                        }
                        &:after{
                            border-color: #fff;
                        }
                    }

                    &:before{
                        content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        -webkit-transform: translate(-50%,-50%);
                        transform: translate(-50%,-50%);
                        border-radius: 50%;
                        width: 56px;
                        height: 56px;
                        background: #fff;
                        opacity: .45;
                    }

                    &:after{
                        content: "";
                        border: solid #878a8e;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 7px;
                        position: relative;
                        top: inherit;
                        opacity: 1;
                        left: inherit;
                        width: 0;
                        height: 0;
                    }

                }

                .slider__next {
                    &:after{
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        right: 3px;
                    }
                }
                .slider__prev {
                    &:after{
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        left: 3px;
                    }
                }
            }
        }
    }

    #popup-upsell-cart, #popup-upsell-cart-inside {

        .section__heading {
            h2.heading {
                font-weight: 400;
                font-style: italic;
                line-height: 100%;
                letter-spacing: normal;
                margin-top: 0;
            }
        }

        .product-grid-item {

            &:not(:last-child) {
                border-bottom: 1px solid #A9A9A9;
            }

            &__details {
                flex-direction: row;
                justify-content: space-between;
                padding: 0;

                .product-data {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                    justify-content: flex-start;

                    .product-grid-item__price {
                        margin: 0;
                    }
                }
            }

            &__link {
                @include mq($screen-m){
                    max-width: 128px;
                }

                @include mq($max-screen: $screen-m){
                    max-width: 80px;
                }
            }

            &__info-details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .product-grid-item__name-link {
                    font-size: 24px;
                    line-height: 100%;
                    font-family: $font-family-base;
                    color: #121212;
                    font-weight: 500;
                }

                @include mq($max-screen: $screen-m - 1) {
                    .product-name {
                        margin: 0;

                        .product-grid-item__name-link {
                            font-size: 16px;
                        }
                    }
                }

                @include mq($screen-m - 1) {
                    gap: 16px;
                }

                .callout-text {
                    color: #666;

                    @include mq($max-screen: $screen-m - 1) {
                        font-size: 14px;
                    }

                    @include mq($screen-m - 1) {
                        font-size: 18px;
                    }
                }

            }

            &__details-right {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .ruk_rating_snippet {
                    text-align: center;

                    .ruk-rating-snippet-count {
                        display: none;
                    }
                }

                .product-grid-item__actions {
                    margin: 0;

                    .button--add-to {
                        border-radius: 4px;
                        border: 1px solid $color-accent-primary;
                        background: #FFF;
                        color: $color-accent-primary;
                        text-transform: none;
                        padding: 0 16px;
                        font-weight: 500;
                        gap: 4px;

                        @include mq($max-screen: $screen-m - 1) {
                            font-size: 10px;
                            height: 32px;
                            min-height: 32px;
                            width: 74px;

                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        @include mq($screen-m - 1) {
                            font-size: 18px;
                            height: 40px;
                            min-height: 40px;
                            width: 88px;
                            min-width: 88px;
                        }

                        svg path {
                            fill: $color-accent-primary;
                        }
                    }
                }
            }
        }
    }

    #popup-upsell-cart {
        .slick-dotted {
            &.slick-slider {
                margin-bottom: 0;
            }
        }

        .section.upsell {
            margin-bottom: 0;
        }

        .slick-initialized {
            .slick-slide {
                vertical-align: top;
            }
        }

        .section__heading {
            position: relative;
            margin-bottom: 0;

            h2 {
                color: $color-accent-primary;
                font-family: "Times New Roman";
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 42px;
                text-align: center;
                text-transform: capitalize;
            }
        }

        .product-grid-item__details {
            padding: 0;

            .stock.unavailable {
                padding: 8px 0;
            }
        }

        .product-grid-item__price {
            .price-label {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    body {

        .modal-upsell.modal-popup.modal-slide {
            height: 90%;
            width: calc(100% - 30px);
            margin: auto;

            .modal-inner-wrap {
                height: auto;
            }
        }

        .modal-upsell.modal-popup {
            .modal__close-button {
                &:before{
                    width: 22px;
                    height: 22px

                }
            }
            .modal-content {
                padding: 0;
                overflow-x: hidden;
            }

            &.modal-slide .modal-footer {
                padding: 20px 0;
                margin-top: 0;
            }

            .modal__close-button {
                top: 0;
                right: 0;
            }

            .modal-inner-wrap {
                .modal__close-button-icon {
                    transform: scale(1);
                }
            }
        }

        #popup-upsell-cart {
            .slider__dots {
                margin-top: 20px;
            }

            .slider--products {
                .slider__item {
                    padding: 5px;
                }
            }

            .section__heading {
                h2 {
                    font-family: "Chaparral Pro";
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: center;
                }
            }

            .product-grid-item__name-link {
                text-align: left;
                max-height: 45px;
            }
        }
    }
}
.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}




.amrelated-pack-wrapper {
    display: flex;
    padding: 32px 48px 48px 48px;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    align-self: stretch;
    background: #FBFBFB;
}

.amrelated-title {
    color: #467235;
    font-family: "Chaparral Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

div.amrelated-pack-wrapper {
    padding: 32px 48px 48px 48px;
    background: #FBFBFB;
}

.amrelated-title strong {
    position: relative;
    font-weight: 600;
}

.amrelated-title strong:before {
    content: "";
    position: absolute;
    width: 54px;
    height: 8px;
    background: url(/static/frontend/Snowdog/mackenzie/en_US/images/section__heading-bg-left.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    left: -70px;
}

.amrelated-title strong:after {
    content: "";
    position: absolute;
    width: 54px;
    height: 8px;
    background: url(/static/frontend/Snowdog/mackenzie/en_US/images/section__heading-bg-right.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    right: -70px;
}

.amrelated-photo-container img {
    max-width: 74px;
}



.amrelated-info {
    display: flex;
    gap: 14px;
    align-items: center;
}

.amrelated-name a {
    color: #467235;
    font-family: "Chaparral Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
}

.amrelated-tocart-wrapper button {
    /* Layout */
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    /* Dimensions */
    min-width: 50px;
    min-height: 50px;
    height: 56px;
    padding: 18px 58px;
    /* Visual */
    border: 1px solid #467235;
    background-color: transparent;
    cursor: pointer;
    /* Typography */
    color: #467235;
    font-family: "Eureka Sans";
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
}

.amrelated-tocart-wrapper button:hover {
    border: 1px solid #f78533;
    color: #f78533;
    transition: all .5s ease-in-out;
}

.amrelated-discount.-summary {
    color: #e62325;
    font-family: "Chaparral Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.main.price {
    color: #467235;
    font-family: "Eureka Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
}

.amrelated-details span {
    color: #6E7577;
    font-family: "Chaparral Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
}

.amrelated-plus {
    display: none;
}

.amrelated-discount.-product {
    display: none;
}

.amrelated-photo-container input {
    display: none;
}

.amrelated-equal {
    display: none;
}

.amrelated-price-wrapper {
    margin-top: 32px;
    margin-bottom: 16px;
}

.amrelated-pack-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.amrelated-details i {
    font-size: 13px !important;
    line-height: 18px;
}

.amrelated-price {
    display: none;
}

.amrelated-pack-items.-selected .amrelated-pack-item:last-child {
    margin-bottom: 0;
}

.amrelated-pack-items.-selected .amrelated-pack-item {
    margin-bottom: 24px;
}

@media screen and (min-width: 769px) {
    .catalog-product-view .product-view__details {
        position: static !important;
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .amrelated-pack-wrapper {
        padding: 32px 16px !important;
        gap: 24px;
    }

    .amrelated-tocart-wrapper button {
        padding: 10px 20px;
    }
}
