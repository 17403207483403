@font-face {
    font-family: 'Eureka Sans';
    src:
    url('../fonts/eureka-sans-regular.woff2') format('woff2'),
    url('../fonts/eureka-sans-regular.woff') format('woff'),
    url('../fonts/eureka-sans-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eureka Sans';
    src:
    url('../fonts/eureka-sans-medium.woff2') format('woff2'),
    url('../fonts/eureka-sans-medium.woff') format('woff'),
    url('../fonts/eureka-sans-medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eureka Sans';
    src:
    url('../fonts/eureka-sans-bold.woff2') format('woff2'),
    url('../fonts/eureka-sans-bold.woff') format('woff'),
    url('../fonts/eureka-sans-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chaparral Pro';
    src:
    url('../fonts/chaparral-pro-regular.woff2') format('woff2'),
    url('../fonts/chaparral-pro-regular.woff') format('woff'),
    url('../fonts/chaparral-pro-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chaparral Pro';
    src:
    url('../fonts/chaparral-pro-semibold.woff2') format('woff2'),
    url('../fonts/chaparral-pro-semibold.woff') format('woff'),
    url('../fonts/chaparral-pro-semibold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Chaparral Pro';
    src:
    url('../fonts/chaparral-pro-bold.woff2') format('woff2'),
    url('../fonts/chaparral-pro-bold.woff') format('woff'),
    url('../fonts/chaparral-pro-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


