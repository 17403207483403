.cms-tv {
    .page-wrapper {
        @media(max-width: 767px) {
            margin-bottom: 0;
        }
    }
    .breadcrumbs.container {
        display: none;
    }
    .cms-page__content {
        max-width: 100%;

        h2.title-swirl {
            font-size: 25px;
            font-style: italic;
            font-weight: 600;
            line-height: 31px;
            color: #215732;
            margin-bottom: 35px;
            @media(min-width: 768px) {
                font-size: 40px;
                margin-bottom: 48px;
            }
            &:before, &:after {
                width: 55px;
                height: 11px;
            }
        }
        h1.title-swirl {
            &:before, &:after {
                width: 55px;
                height: 11px;
            }
        }
    }
    .tv {
        &__video-banner {
            @media(max-width: 767px) {
                margin: 25px -15px 0 -15px;
            }
        }
        &__content-section {
            margin-top: 24.19px;
            @media(min-width: 768px) {
                margin-top: 64px;
            }
            figure {
                margin: 0;
            }
            > figure {
                &.pagebuilder-mobile-only {
                    margin-top: 21px;
                    img.pagebuilder-mobile-only {
                        margin: auto;
                        padding: 0 15px;
                    }
                }
            }
            h2.heading {
                font-size: 25px;
                font-style: italic;
                font-weight: 600;
                color: #215732;
                margin-bottom: 21px;
                text-align: center;
                @media(min-width: 1200px) {
                    font-size: 40px;
                    margin-bottom: 48px;
                }
                @media(min-width: 768px) and (max-width: 1199px) {
                    margin-bottom: 48px;
                    font-size: 40px;
                }
                @media(max-width: 767px) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .pagebuilder-column-line {
                @media(max-width: 767px) {
                    flex-direction: column-reverse;
                }
            }
            .pagebuilder-column-group {
                @media(max-width: 767px) {
                    margin: 0 -15px;
                }
            }
            .images-column {
                @media(min-width: 1200px) {
                    margin-right: 39px;
                    width: 33.3333% !important;
                    figure {
                        img {
                            margin-bottom: 12.86px;
                        }
                    }
                }
                @media(min-width: 768px) and (max-width: 1199px) {
                    margin-right: 20px;
                    width: 45% !important;
                    figure {
                        img {
                            margin-bottom: 12.86px;
                        }
                    }
                }
                @media(max-width: 767px) {
                    width: 100% !important;
                    flex-direction: row !important;
                    gap: 11.58px;
                    > figure {
                        width: 100%;
                        img.pagebuilder-mobile-only {
                            width: 100%;
                        }
                    }
                }
            }
            .text-column {
                @media(min-width: 1200px) {
                    margin-left: 39px;
                    width: 66.6667% !important;
                }
                @media(min-width: 768px) and (max-width: 1199px) {
                    margin-left: 20px;
                    width: 55% !important;
                }
                @media(max-width: 767px) {
                    width: 100% !important;
                    padding: 0 10%;
                    margin-bottom: 21px;
                }
                [data-content-type="text"] {
                    max-width: 705px;
                }
                p {
                    font-family: "Eureka Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    color: #215732;
                    text-align: center;
                    @media(min-width: 1200px) {
                        font-size: 24px;
                        margin-bottom: 40px;
                        line-height: 40px;
                        text-align: left;
                    }
                    @media(min-width: 768px) and (max-width: 1199px) {
                        font-size: 24px;
                        line-height: 40px;
                        margin-bottom: 20px;
                        text-align: left;
                    }
                }
            }
        }
        &__as-featured-on-tv {
            margin-top: 40px;
            @media(min-width: 768px) {
                margin-top: 100px;
            }
            @media(max-width: 767px) {
                margin-bottom: 80px;
            }
            h2.heading {
                font-size: 25px;
                font-style: italic;
                font-weight: 600;
                line-height: 31px;
                color: #215732;
                margin-bottom: 16px;
                @media(min-width: 768px) {
                    font-size: 40px;
                    margin-bottom: 48px;
                }
                @media(max-width: 767px) {
                    height: 54px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .section__content--products {
                .slider__container {
                    max-width: 1140px;
                    margin: auto;
                    @media(max-width: 767px) {
                        padding: 0 20px;
                    }
                }
                .slider__navigation {
                    @media(min-width: 768px) {
                        display: block;
                    }
                    @media(max-width: 767px) {
                        position: unset;
                    }
                    .slick-arrow:not(:hover) {
                        &:before {
                            background: #F2F1EC;
                        }
                    }
                    .slick-arrow {
                        @media(max-width: 767px) {
                            top: unset;
                            bottom: -55px;
                            &.slick-prev {
                                left: calc(50% - 45px);
                            }
                            &.slick-next {
                                right: calc(50% - 45px);
                            }
                        }
                    }
                }
                .slider__dots {
                    @media(min-width: 768px) {
                        display: none !important;
                    }
                }
                .product-grid-item {
                    &__rating {
                        .ruk_rating_snippet i {
                            color: #4E5147;
                        }
                    }
                    &__sku {
                        line-height: 25.6px;
                        font-weight: 500;
                    }
                    &__link {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                }
            }
        }
        &__explore-more {
            margin-top: 40px;
            @media(min-width: 768px) {
                margin-top: 100px;
            }
        }
        &__bottom-content {
            margin-top: 60px;
            @media(min-width: 768px) {
                margin-top: 122px;
            }
            p {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                color: #215732;
                @media(min-width: 768px) {
                    font-size: 20px;
                    line-height: 32px;
                }
                @media(max-width: 767px) {
                    text-align: center;
                }
            }
        }
    }
}
