$content-block__padding--secondary          : $spacer--large $spacer--medium $spacer--medium;
$content-block__padding--secondary\@medium  : $content-block__padding--secondary;
$content-block__padding--secondary\@large   : $content-block__padding--secondary;

$content-block__heading-border--secondary   : none;
$content-block__heading-padding             : 0;
$content-block__heading-line-height         : $font-line-height;

$content-block__introduction-margin\@medium : 0 0 $spacer--medium;

$font-size-giftcard-title: 30px;
$font-size-giftcard-button: 20px;
