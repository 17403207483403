$minicart-content__action-button-bg-color: $orange2;

$minicart-background-color: #FFF;

$input-discount-border-color: #597C44;

$button-discount-bg-color: #597C44;
$button-discount-bg-color-hover: $orange2;

$button-add-all-color: #467235;
$button-add-all-border-color: #597C44;

$button-color-added: #597c44;

$success-message-color: #4F7D3C;
