//about
$about-content-right-max-width: 545px;

$about-title-font-family: $font-family-secondary;
$about-title-font-size: 40px;
$about-title-font-weight: 600;
$about-title-font-color: $gray-dark2;
$about-title-line-line-height: 48px;

$about-paragraph-medium-font-family: $font-family-secondary;
$about-paragraph-medium-font-size: 25px;
$about-paragraph-medium-font-weight: $font-weight-normal;
$about-paragraph-medium-font-color:  $gray-dark2;
$about-paragraph-medium-line-height: 30px;
$about-paragraph-medium-margin: 20px 0 0 ;

$about-paragraph-normal-font-family: $font-family-base;
$about-paragraph-normal-font-size: 20px;
$about-paragraph-normal-font-weight: $font-weight-normal;
$about-paragraph-normal-font-color:  $gray-light2;
$about-paragraph-normal-line-height: 26px;
$about-paragraph-normal-margin: 30px 0 0;

$about-paragraph-bottom-font-family: $font-family-secondary;
$about-paragraph-bottom-font-size: 40px;
$about-paragraph-bottom-font-weight: 600;
$about-paragraph-bottom-font-color:  $gray-dark2;
$about-paragraph-bottom-font-style: italic;
$about-paragraph-bottom-line-height: 48px;
$about-paragraph-bottom-margin: 30px 0 0;


$about-image-margin: 42px 0 0;

//tablet
$about-title-font-size\tablet: 25px;
$about-title-line-line-height\tablet: 30px;

$about-paragraph-medium-font-size\tablet: 18px;
$about-paragraph-medium-line-height\tablet: 22px;
$about-paragraph-medium-margin\tablet: 7px 0 0 ;

$about-image-margin\tablet: 17px 0 0;

$about-paragraph-normal-font-size\tablet: 18px;
$about-paragraph-normal-line-height\tablet: 24px;
$about-paragraph-normal-margin\tablet: 17px 0 0;

$about-paragraph-bottom-font-size\tablet: 25px;
$about-paragraph-bottom-line-height\tablet: 31px;
$about-paragraph-bottom-margin\tablet: 17px 0 0;
